import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import PageBanner from "../components/Common/PageBanner"
import { GetStartedWithUs } from "../components/Common/GetStartedWithUs"
import { CommonHeaderAndFooter } from "../layout/CommonHeaderAndFooter"
import { SEO_TITLES } from "../config/seo"
import { PageBannerSubheading } from "../components/Common/PageBannerSubheading"

const FaqRow = ({
                  item,
                  className,
                  answerPredix = (<strong>A:</strong>),
                  questionPrefix = (<strong>Q:</strong>),
  useQuestionPrefix = true,
                  useAnswerPrefix = true,
                }) => {
  return (
    <div className={cx("col-lg-6 col-md-6", className)}>
      <div className="faq-item">
        <h3>{useQuestionPrefix ? questionPrefix : null} {" "} {item.question}</h3>
        <p>{useAnswerPrefix ? answerPredix : null} {" "} {item.answer}</p>
      </div>
    </div>
  )
}

FaqRow.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  questionPrefix: PropTypes.node,
  answerPrefix: PropTypes.node,
  useQuestionPrefix: PropTypes.bool,
  useAnswerPrefix: PropTypes.bool,
}

const FAQ_CONFIG = {
  items: [
    {
      question: `What is digital marketing?`,
      answer: `
        Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels
        such as search engines, social media, email, websites and apps to connect with prospects and
        customers.
      `
    },
    {
      question: `What is digital marketing?`,
      answer: `
        Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels
        such as search engines, social media, email, websites and apps to connect with prospects and
        customers.
      `
    },
    {
      question: `What is digital marketing?`,
      answer: `
        Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels
        such as search engines, social media, email, websites and apps to connect with prospects and
        customers.
      `
    },
    {
      question: `What is digital marketing?`,
      answer: `
        Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels
        such as search engines, social media, email, websites and apps to connect with prospects and
        customers.
      `
    }
  ]
}

const Faq = () => {
  return (
    <CommonHeaderAndFooter seoTitle={SEO_TITLES.VARIANT3.FAQ}>

      <PageBanner
        showShapes={false}
        showLines={true}
        compact={true}
        pageTitle="Frequently Asked Questions"
        subheading={
          <PageBannerSubheading>Get the answers to the most common questions</PageBannerSubheading>
        }
      />

      <div className="faq-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            {FAQ_CONFIG.items.map((item) => (
              <FaqRow item={item} useQuestionPrefix={false} useAnswerPrefix={false} />
            ))}
          </div>
        </div>
      </div>

      <div className="bg-f9f9f9">
        <GetStartedWithUs />
      </div>

    </CommonHeaderAndFooter>
  )
}

export default Faq