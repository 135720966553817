import React from 'react';
import PropTypes from "prop-types"
import cx from 'classnames';
import _ from 'lodash';

import './PageBannerSubheading.scss';

export const PageBannerSubheading = ({
  children,
                                       className,
}) => {
  return (
    <h5 className={cx('pt-3 tac page-banner-subheading', className)}>{children}</h5>
  );
}

PageBannerSubheading.propTypes = {
  children: PropTypes.node.isRequired,
}